import React, {useEffect} from 'react'

const TermsRedirect = () => {

  useEffect(() => {
    window.location.href = '/copaysavingsprogram/smsterms/';
    return () => {
      window.location.href = '/copaysavingsprogram/smsterms/';
    }
  }, [])

  return (
   <div></div>
  )
}
export default TermsRedirect
